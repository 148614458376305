<template>
  <div id="tenant" style="overflow-x:hidden !important;" class="apply-background-color">
    <v-overlay :value="tenantLoading">
      <v-img contain width="200" height="150" :src="require('@/assets/syfol-spin.gif')" v-if="appName === 'syfol'"></v-img>
      <v-progress-circular :size="70" :width="4" color="primary" indeterminate v-else></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar" tile :color="snackbarColor">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-container class="py-0">
      <v-row justify="center">
        <v-row v-if="tenantLoader" class="justify-center pt-5">
          <v-overlay v-if="tenantLoader">
            <v-img contain width="200" height="150" :src="require('@/assets/syfol-spin.gif')" v-if="appName === 'syfol'"></v-img>
            <v-progress-circular :size="70" :width="4" color="primary" indeterminate v-else></v-progress-circular>
          </v-overlay>
        </v-row>
        <v-col v-else cols="12">
          <v-row class="mt-1" justify="center">
            <v-toolbar width="100%" absolute color="#001a4d" class="white--text">
              <v-flex class="d-flex justify-center headline font-weight-medium">{{ listOfTenants && listOfTenants.length > 0 ?
              'Choose your workspace' : 'No Tenants found' }}
              </v-flex>
            </v-toolbar>
          </v-row>
        </v-col>
        <v-col cols="12" v-for="(item, index) in listOfTenants" :key="index">
          <v-container>
            <v-row>
              <v-col cols="12" class="text-center pt-10">
                <span style="color:black" class="subtitle font-weight-bold">{{ item.company }}</span>
              </v-col>
              <v-col cols="12" xs="12" class="pt-0" v-for="(tenant, i) in item.tenants" :key="i" @click="loginWithChooseTenant(tenant)">
                <v-card width="100%" style="border:1px solid #E0E0E0 !important" height="56"  tile elevation="">
                  <v-container>
                    <v-row  justify="center">
                      <v-col  class="pt-0 pt-1 pr-0" sm="1" cols="3">
                        <v-avatar tile size="45"  class="mb-0" color="#EEEEEE">
                          <v-avatar size="50">
                            <v-img :src="tenant.logo ? `${appUrl}${tenant.logo}` : require(`@/../public/img/${appName}/logo.png`)"
                              contain height="28" width="37">
                            </v-img>
                          </v-avatar>
                        </v-avatar>
                      </v-col>
                      <v-col  cols="7" sm="10" class="align-self-center pl-0 mb-2">
                        <span class="caption font-weight-medium">
                          {{ tenant.name }}
                        </span>
                      </v-col>
                      <v-col sm="1" cols="2">
                        <v-menu bottom left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" class="pb-1" :ripple="false">
                              <v-icon size="20">mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list tile dense class="pa-0">
                            <v-list-item-group>
                              <v-list-item :ripple="false" class="pl-2"
                                :style="tenant.isdefault ? {backgroundColor:'#1E88E5'}:''" @click.stop="exceuteHandler(tenant,index)">
                                <v-list-item-title class="subtitle-2">
                                  <v-icon size="20" v-if="tenant.isdefault" :color="tenant.isdefault ? 'white':'#1E88E5'">mdi-check</v-icon>
                                  <v-icon size="20" v-else :color="tenant.isdefault ? 'white':'#1E88E5'">mdi-close</v-icon>
                                  <span class="ml-2 caption font-weight-bold" :class="tenant.isdefault ? 'white--text':''">Set as my default workspace</span>
                                </v-list-item-title>
                              </v-list-item>
                              <v-list-item :ripple="false" class="pl-2" v-if="item.isdomainadmin"
                                @click.stop="openEditTenantDialogHandler(tenant)">
                                <span>
                                  <v-icon color="primary" size="20">mdi-square-edit-outline</v-icon>
                                  <span class="ml-2 font-weight-bold caption">Update workspace</span>
                                </span>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
              <v-col cols="12" xs="12" class="pt-0" v-if="item.isdomainadmin">
                <v-card width="100%" style="border:1px solid #E0E0E0 !important" height="56"  tile elevation="" @click="openCreateTenantDialog(item.domain)">
                  <v-container fill-height fluid>
                    <v-row align="center" align-content="center"
                        justify="center">
                        <v-col cols="12" align-self="center">
                          <v-btn color="black" small  text>
                            <v-icon color="black" class="pr-6">mdi-plus</v-icon>
                            Create new workspace
                          </v-btn>
                        </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-btn
      right bottom fab small color="error darken-1" fixed
      @click="logoutClickHandler"
    >
      <v-icon size="19"> mdi-logout-variant </v-icon>
    </v-btn>
    <v-dialog v-model="createorUpdateTenantDialog" width="500px">
      <v-card tile>
        <div style="font-size:1.3em;" class="text-center font-weight-medium pa-3">
          {{ !tenantObj._id ? 'Create workspace' : 'Update workspace' }}
        </div>
        <v-divider></v-divider>
        <v-card-text class="mt-3 pb-0">
          <v-form ref="workspaceForm">
            <v-row no-gutters class="pt-2">
              <v-col cols="12" class="py-1" lg="12">
                <v-text-field dense label="Name" hide-details v-model="tenantObj.name"
                  outlined :rules="$_requiredValidation"></v-text-field>
              </v-col>
              <v-col cols="12" class="py-1"  lg="12">
                <v-checkbox dense label="Set as my default workspace" hide-details
                  v-model="tenantObj.isdefault" outlined></v-checkbox>
              </v-col>
              <v-col cols="12" lg="12" v-if="customerDomainInfo.issharepointstorage">
                <v-checkbox dense label="Use Sharepoint for storage" hide-details v-model="tenantObj.issharepoint" outlined ></v-checkbox>
                <code class="ml-3 checkbox-warning red--text pt-1">Note:Changing storage will cause data loss</code>
              </v-col>
              <v-col cols="12" lg="12" class="pa-0 py-1 mt-2">
                <label style="font-size:1.3em;" class="ml-2 font-weight-bold">Who can Access it ?</label>
              </v-col>
              <v-col cols="12" lg="12" class="py-1" >
               <v-radio-group class="mt-0" v-model="tenantObj.whoHasAccessType" @change="showUsersSelect(true)">
                  <v-radio row
                    v-for="n in whoHasAccessList"
                    :key="n.id"
                    :label="n.name"
                    :value="n.id"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" class="pa-0" v-if="showUsers">
                <v-autocomplete label="Users" :items="usersList" dense hide-details
                  outlined item-text="email" item-value="email"
                  :loading="userLoading" v-model="tenantObj.msUsers" multiple>
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index === 0">{{ item['name'] }}
                    </span>
                    <span v-if="index === 1" class="grey--text ma-0 caption">
                    (+{{ tenantObj.msUsers.length - 1 }}
                    {{ $t('others') }})
                    </span>
                  </template>
                  <template v-slot:append>
                    <v-slide-x-reverse-transition mode="out-in">
                      <v-icon  class="mt-1" :title="$t('quickAdd')" color="secondary" outlined dense>
                        mdi-account-multiple-plus
                      </v-icon>
                    </v-slide-x-reverse-transition>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider class="pa-1"></v-divider>
        <v-card-actions>
          <v-flex class="text-center">
            <v-btn tile color="primary" @click="saveOrUpdateTenantHandler()" class="mr-5" :loading="loading">
              {{ tenantObj._id ? 'Update' : 'Create' }}
            </v-btn>
            <v-btn tile color="error" @click="closeTenantDialogHandler()">
              Close
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { loadLanguageAsync } from '../../lang/config'
import { getUpdatedTimeZone } from '@/plugins/axios_settings'
export default {
  data () {
    return {
      selectedItem: 0,
      createorUpdateTenantDialog: false,
      domain: '',
      snackbarText: '',
      snackbarColor: '',
      snackbar: false,
      customerDomainInfo: {},
      selectedId: '',
      updateTenantDialog: false,
      tenantName: '',
      type: 1,
      appUrl: process.env.VUE_APP_IMAGE_URL,
      listOfTenants: [],
      tenantObj: {
        isdefault: false,
        issharepoint: false
      },
      usersList: [],
      userLoading: false,
      showUsers: false,
      loading: false,
      tenantLoader: false,
      choosenTenantLoader: false,
      appLogo: require(`@/../public/img/${process.env.VUE_APP_LOGO_ICONS_FOLDER}/logo.png`),
      appName: process.env.VUE_APP_LOGO_ICONS_FOLDER,
      tenantLoading: false
    }
  },
  mounted () {
    if (this.$route.query && this.$route.query.userswitchworkspace) {
      if (!this.$cookie.get(process.env.VUE_APP_TOKEN) && !this.$cookie.get(process.env.VUE_APP_NAME + '_secondary_tokenPWA')) this.$router.push('/login')
      else {
        this.tenantLoader = true
        this.$api.execute('post', 'auth/switch_tenant').then(response => {
          window.localStorage.setItem(process.env.VUE_APP_CUSTOMERSETUP, JSON.stringify(response.data))
          this.$store.commit('multipleTenants', response.data)
          this.$store.commit('xsetuptoken', response.data.authtoken)
          this.setCustomerSetupData()
        }).catch(() => {
          this.$router.push('/login')
        }).finally(() => {
          this.tenantLoader = false
        })
      }
    } else {
      this.getListOfTenantsFromLocalstorage()
    }
    if (!this.customerDomainInfo.isadmin && this.listOfTenants.length) {
      const val = JSON.parse(window.localStorage.getItem(`${process.env.VUE_APP_NAME}_defaultTenant`))
      if (val) this.listOfTenants[val.index].isdefault = val.value
    }
    // document.getElementById('tenant').style.overflowY = 'auto'
  },
  computed: {
    ...mapGetters(['getTenantProfileObj'])
  },
  methods: {
    async setCustomerSetupData () {
      let getCustomerInformation = window.localStorage.getItem(process.env.VUE_APP_CUSTOMERSETUP)
      getCustomerInformation = getCustomerInformation ? JSON.parse(getCustomerInformation) : null
      this.customerDomainInfo = getCustomerInformation
      if (this.customerDomainInfo) this.$store.state.getTenantProfileObj = this.$formatter.cloneVariable(this.customerDomainInfo)
      let listOfTenants = null
      if (this.getTenantProfileObj && Object.keys(this.getTenantProfileObj).length) {
        listOfTenants = this.$formatter.cloneVariable(this.getTenantProfileObj)
      } else if (this.customerDomainInfo) {
        listOfTenants = this.customerDomainInfo
        this.$store.commit('setTenantProfile', this.customerDomainInfo)
      }
      if (listOfTenants && listOfTenants.availabletenants && listOfTenants.availabletenants.length > 0) {
        let listOfFilteredTenants = []
        if (!getCustomerInformation.isadmin) {
          listOfFilteredTenants = listOfTenants.availabletenants.filter(x => !x.whocanaccess || x.whocanaccess.length === 0 || x.whocanaccess.includes(this.customerDomainInfo.email))
        } else listOfFilteredTenants = listOfTenants.tenants
        listOfFilteredTenants.forEach(x => {
          x.isdefault = x._id === this.customerDomainInfo.defaultworkspace
        })
        this.listOfTenants = listOfFilteredTenants
      } else this.listOfTenants = []
    },
    saveOrUpdateTenantHandler () {
      if (this.$refs.workspaceForm.validate()) {
        this.loading = true
        const model = this.$formatter.cloneVariable(this.tenantObj)
        switch (model.whoHasAccessType) {
          case 1: model.whocanaccess = []
            break
          case 2: model.whocanaccess = [this.customerDomainInfo.email]
            break
          case 3: model.whocanaccess = model.msUsers.includes(this.customerDomainInfo.email) ? model.msUsers : [this.customerDomainInfo.email, ...model.msUsers]
            break
        }
        if (!model.isdefault) model.isdefault = false
        // const setHasDefault = model.isdefault
        model.customerdomain = this.tenantObj.customerdomain
        const url = model._id ? `initialsetup/update_tenant/${model._id}?isdefault=${model.isdefault}` : `initialsetup/create_new?isdefault=${model.isdefault}`
        this.$api.execute(model._id ? 'put' : 'post', url, model).then(response => {
          const listOfTenants = this.$formatter.cloneVariable(this.listOfTenants)
          listOfTenants.forEach((tenant, index) => {
            const workspaceFound = tenant.tenants.findIndex(x => x._id === response.data._id)
            if (workspaceFound !== -1) {
              this.listOfTenants[index].tenants[workspaceFound] = {
                ...this.listOfTenants[index].tenants[workspaceFound],
                name: response.data.name,
                whocanaccess: model.whocanaccess,
                isdefault: model.isdefault,
                issharepoint: model.issharepoint
              }
            }
          })
          // listOfTenants.forEach((tenant, index) => {
          //   if (tenant._id === response.data._id) {
          //     this.listOfTenants[index] = { ...this.listOfTenants[index], name: response.data.name, whocanaccess: model.whocanaccess, isdefault: model.isdefault }
          //   } else {
          //     if (setHasDefault) this.listOfTenants[index].isdefault = false
          //   }
          // })
          this.$forceUpdate()
          this.showMsgAndLoginHandler(model, response)
        }).finally(() => {
          this.closeTenantDialogHandler()
          this.loading = false
        })
      }
    },
    showMsgAndLoginHandler (model, response) {
      this.snackbar = true
      this.snackbarColor = 'success'
      this.snackbarText = model._id ? 'Updated Successfully' : 'Created Successfully!'
      this.currentStep = 4
      if (!model._id) {
        // model.email = this.customerDomainInfo.email
        model.username = this.customerDomainInfo.email
        model.authtoken = this.customerDomainInfo.authtoken
        model.domain = response.data.domain
        this.loginInHandler(model)
      }
    },
    exceuteHandler (item, index) {
      this.listOfTenants[index].isdefault = !this.listOfTenants[index].isdefault
      const defaultTenant = { index: index, value: this.listOfTenants[index].isdefault }
      if (!this.customerDomainInfo.isadmin) window.localStorage.setItem(`${process.env.VUE_APP_NAME}_defaultTenant`, JSON.stringify(defaultTenant))
      this.$api.execute('put', `initialsetup/update_tenant/${item._id}?isdefault=${defaultTenant.value}`, item).then(response => {
        this.snackbar = true
        this.snackbarColor = 'success'
        this.snackbarText = 'Updated Successfully'
        const listOfTenants = this.$formatter.cloneVariable(this.listOfTenants)
        listOfTenants.forEach((y, i) => {
          // this.listOfTenants[i].isdefault = item._id === y._id && item.isdefault
          this.listOfTenants[i].isdefault = item._id === y._id && defaultTenant.value
        })
      })
    },
    openEditTenantDialogHandler (tenantObj) {
      if (!this.choosenTenantLoader) {
        this.showUsers = false
        if (tenantObj) {
          this.tenantObj = this.$formatter.cloneVariable(tenantObj)
          this.tenantObj.msUsers = []
          if (this.tenantObj.whocanaccess && this.tenantObj.whocanaccess.length !== 0) {
            if (this.tenantObj.whocanaccess.length === 1) this.tenantObj.whoHasAccessType = 2
            else {
              this.tenantObj.whoHasAccessType = 3
              this.showUsersSelect(true)
              this.tenantObj.msUsers = tenantObj.whocanaccess
            }
          } else this.tenantObj.whoHasAccessType = 1
          if (this.customerDomainInfo.email && !this.tenantObj.msUsers.includes(this.customerDomainInfo.email)) this.tenantObj.msUsers.push(this.customerDomainInfo.email)
        }
        this.createorUpdateTenantDialog = true
      }
    },
    openCreateTenantDialog (domain) {
      if (!this.choosenTenantLoader) {
        this.tenantObj.whoHasAccessType = 1
        if (this.customerDomainInfo.issharepointstorage) this.tenantObj.issharepoint = true
        this.createorUpdateTenantDialog = true
        this.tenantObj.msUsers = this.customerDomainInfo.email ? [this.customerDomainInfo.email] : []
        this.tenantObj.customerdomain = domain
      }
    },
    closeTenantDialogHandler () {
      this.createorUpdateTenantDialog = false
      this.$refs.workspaceForm.resetValidation()
      this.tenantObj = { whoHasAccessType: 1 }
      this.showUsers = false
    },
    loginWithChooseTenant (tenantObj) {
      if (!this.choosenTenantLoader) {
        const model = {
          authtoken: this.getTenantProfileObj.authtoken,
          // email: this.getTenantProfileObj.email,
          username: this.getTenantProfileObj.email,
          domain: tenantObj.domain
        }
        this.loginInHandler(model, true)
        window.localStorage.removeItem(`${process.env.VUE_APP_NAME}_defaultTenant`)
      }
      this.tenantLoading = true
      this.$root.$emit('removeTransition', false)
    },
    loginInHandler (model, switchingWorkspace = false) {
      this.choosenTenantLoader = true
      this.$api.execute('post', 'auth/login_user_after_setup', model)
        .then(({ data }) => {
          if (data) {
            if (data.isactive) {
              const lang = this.$cookie.get(process.env.VUE_APP_LOCALE) || 'en'
              loadLanguageAsync({ lang, domain: model.domain, forceUpdate: true })
                .then(() => {
                  this.afterTenantCreationHandler(data, switchingWorkspace)
                  // update timezone in moment
                  this.$formatter.updateTimeZone()
                  const timezone = getUpdatedTimeZone()
                  this.$moment.tz.setDefault(timezone)
                })
            } else this.$root.$emit('snackbar', { snackbar: true, color: 'apiError', text: "Sorry! You don't have access to this workspace. Please contact your Administrator.", isException: true })
          }
        }).catch(() => {
          this.$router.push('/login')
        }).finally(() => {
          this.choosenTenantLoader = false
          this.tenantLoading = false
        })
    },
    getCustomerDomainInfo () {
      this.$api.execute('post', 'auth/switch_tenant').then(response => {
        return response.data
      })
    },
    constructListOfTenants (listOfTenants, customerInfo) {
      if (listOfTenants && listOfTenants.availabletenants && listOfTenants.availabletenants.length > 0) {
        let listOfFilteredTenants = []
        if (customerInfo && !customerInfo.isadmin) {
          listOfTenants.availabletenants.forEach(domain => {
            domain.tenants = domain.tenants.filter(x => !x.whocanaccess || x.whocanaccess.length === 0 || x.whocanaccess.includes(customerInfo.email))
          })
          listOfFilteredTenants = listOfTenants.availabletenants
        } else listOfFilteredTenants = listOfTenants.availabletenants
        if (customerInfo) {
          listOfFilteredTenants.forEach(x => {
            x.isdefault = x._id === customerInfo.defaultworkspace
          })
        }
        this.listOfTenants = listOfFilteredTenants
      } else this.listOfTenants = []
    },
    getListOfTenantsFromLocalstorage () {
      let getCustomerInformation = window.localStorage.getItem(process.env.VUE_APP_CUSTOMERSETUP)
      getCustomerInformation = getCustomerInformation ? JSON.parse(getCustomerInformation) : null
      if (getCustomerInformation) {
        this.$store.commit('setTenantProfile', getCustomerInformation)
        this.$store.commit('xsetuptoken', getCustomerInformation.authtoken)
        const listOfTenants = this.$formatter.cloneVariable(getCustomerInformation)
        this.customerDomainInfo = getCustomerInformation
        this.constructListOfTenants(listOfTenants, getCustomerInformation)
      }
    },
    logoutClickHandler () {
      this.$store.dispatch('logout')
      this.$router.push('/login')
    }
  },
  beforeRouteLeave (to, from, next) {
    window.localStorage.removeItem(process.env.VUE_APP_CUSTOMERSETUP)
    next()
  },
  beforeDestroy () {
    window.localStorage.removeItem(process.env.VUE_APP_CUSTOMERSETUP)
  }
}
</script>
<style>
.tenantProfile aside {
  width: auto !important;
}
.apply-background-color {
    background-color: #ffffff;
    background-size: cover;
    overflow:auto;
    height: 100vh;
}
.checking .v-label {
  font-size: 8.3px !important;
}
</style>
